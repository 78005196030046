<template>
  <InputTextDate
    v-model="dateStart"
    :name="'date-start'"
    @focus="isError = false"
    @blur="checkError()"
    :error="isError"
  />
</template>

<script>
import { ref, computed, watch } from "vue";

import InputTextDate from "@/components/UI/InputTextDate.vue";

export default {
  components: {
    InputTextDate,
  },

  props: {
    modelValue: String,
  },

  emits: ["update:modelValue", "update:isValidDateStart"],

  setup(props, { emit }) {
    const dateStart = ref(props.modelValue);

    // Two-way binding
    watch(dateStart, (newDateStart) => emit("update:modelValue", newDateStart));
    watch(
      () => props.modelValue,
      (newDateStart) => (dateStart.value = newDateStart)
    );

    // Check out error
    const isError = ref(false);
    const checkError = () => {
      isError.value = !isValidDateStart.value;
    };

    // Validation
    const isValidDateStart = computed(() => {
      return !/d|m|y|_/.test(dateStart.value) && !!dateStart.value;
    });
    watch(
      isValidDateStart,
      (newIsValid) => emit("update:isValidDateStart", newIsValid),
      { immediate: true }
    );

    return {
      dateStart,
      isError,
      checkError,
      isValidDateStart,
    };
  },
};
</script>

<style scoped>
@media (max-width: 1200px) {
}
</style>
