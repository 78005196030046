<template>
  <div class="container">
    <div class="container-date-start">
      <BaseH1 :text="'Kiedy niania musi zacząć pracę?'" class="h1" />
      <InputTextDateStart
        v-model="schedule.dateStart.string"
        @update:is-valid-date-start="schedule.dateStart.isValid = $event"
      />
    </div>

    <div class="container-period">
      <BaseH1 :text="'Na godziny chyli dni szukasz niani?'" class="h1" />
      <PeriodMeasureInputRadioGroupHorizontal
        v-model="schedule.period.measure.value"
        :name="'period-measure-babby-sitting'"
        :measure-options="periodMeasureOptions"
      />
      <PeriodAmountInputNumberSpinner
        v-show="periodMeasure"
        v-model="schedule.period.amount"
        :name="'period-amount-babby-sitting'"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import { dateToString, stringToDate } from "@/helpers";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputTextDateStart from "@/components/UI/InputTextDateStart.vue";
import PeriodMeasureInputRadioGroupHorizontal from "@/components/PeriodMeasureInputRadioGroupHorizontal.vue";
import PeriodAmountInputNumberSpinner from "@/components/PeriodAmountInputNumberSpinner.vue";

export default {
  components: {
    BaseH1,
    InputTextDateStart,
    PeriodMeasureInputRadioGroupHorizontal,
    PeriodAmountInputNumberSpinner,
  },

  props: {
    scheduleDateStart: [String, Date],
    periodMeasure: String,
    periodAmount: Number,
  },

  emits: [
    "update:scheduleDateStart",
    "update:periodTerm",
    "update:periodMeasure",
    "update:periodAmount",
    "update:isValid",
  ],

  setup(props, { emit }) {
    const schedule = reactive({
      dateStart: {
        string: "",
        date: null,
        isValid: false,
      },
      period: {
        term: "custom",
        measure: {
          value: ["hours", "days"].includes(props.periodMeasure)
            ? props.periodMeasure
            : "days",
          get isValid() {
            return ["hours", "days"].includes(this.value);
          },
        },
        amount: props.periodAmount,
      },
    });

    // Way out binding
    watch(
      () => schedule.dateStart.date,
      (newDate) => {
        if (
          props.scheduleDateStart &&
          dateToString(props.scheduleDateStart) === dateToString(newDate)
        ) {
          return;
        }

        emit("update:scheduleDateStart", newDate);
      }
    );
    watch(
      () => schedule.period.term,
      (newPeriodTerm) => emit("update:periodTerm", newPeriodTerm),
      { immediate: true }
    );
    watch(
      () => schedule.period.measure.value,
      (newPeriodMeasure) => emit("update:periodMeasure", newPeriodMeasure),
      { immediate: true }
    );
    watch(
      () => schedule.period.amount,
      (newPeriodAmount) => emit("update:periodAmount", newPeriodAmount)
    );

    // Way in binding
    watch(
      () => props.scheduleDateStart,
      (newDate) => {
        if (!newDate) return;

        schedule.dateStart.string = dateToString(newDate);
      },
      { immediate: true }
    );
    watch(
      () => props.periodMeasure,
      (newPeriodMeasure) => (schedule.period.measure.value = newPeriodMeasure)
    );
    watch(
      () => props.periodAmount,
      (newPeriodAmount) => (schedule.period.amount = newPeriodAmount)
    );

    // Component inside binding for dateStart
    watch(
      () => schedule.dateStart.string,
      (newDateString) => {
        // Validate new dob string
        if (/d|m|y|_/.test(newDateString) || newDateString.length === 0) return;

        if (
          schedule.dateStart.date &&
          dateToString(schedule.dateStart.date) === newDateString
        ) {
          return;
        }

        const newDate = stringToDate(newDateString);
        schedule.dateStart.date = newDate;
      }
    );

    const isValid = computed(() => {
      return schedule.dateStart.isValid && schedule.period.measure.isValid;
    });
    watch(isValid, (newIsValid) => emit("update:isValid", newIsValid), {
      immediate: true,
    });

    const periodMeasureOptions = [
      {
        label: "Godziny",
        value: "hours",
      },
      {
        label: "Dni",
        value: "days",
      },
    ];

    return {
      schedule,
      isValid,
      periodMeasureOptions,
    };
  },
};
</script>

<style scoped>
.h1 {
  width: 360px;
}
.container {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 120px;
}
.container-date-start {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 80px;
}
.container-period {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 80px;
}

@media (max-width: 1200px) {
  .h1 {
    width: 100%;
  }
}
</style>
