<template>
  <block-content-13>
    <template #left>
      <div class="content__container-left-fixed">
        <NavSideBarLeft :links="links" />
      </div>
    </template>
    <template #right>
      <div class="content__container-right">
        <div id="address" class="wrapper-section">
          <OfferNannyInputAddress
            v-model:address="offerNanny.details.location.address"
            @update:is-valid="isValid = $event"
            v-model:coordinates-lat-lng="coordsLatLng"
            :zoom="16"
          />
        </div>
        <div id="aupair" class="wrapper-section">
          <OfferNannyInputAupairRadio
            v-model:aupair="offerNanny.details.aupair"
            @update:is-valid="isValid = $event"
          />
        </div>
        <div id="kids" class="wrapper-section">
          <OfferNannyInputKids
            v-model:kids="offerNanny.details.kids"
            v-model:is-special-care="offerNanny.details.isSpecialCare"
          />
        </div>
        <div id="working-hours" class="wrapper-section">
          <OfferNannyInputEmploymentTypeRadio
            id="employment-type"
            v-model:employment-type="offerNanny.employment.type"
          />
        </div>
        <div
          v-if="offerNanny.employment.type === 'part_time'"
          id="employment-regularity"
          class="wrapper-section"
        >
          <OfferNannyInputEmploymentRegularityRadio
            v-model:employment-regularity="offerNanny.employment.regularity"
          />
        </div>
        <div
          v-if="offerNanny.employment.type === 'baby_sitting'"
          id="period-baby-sitting"
          class="wrapper-section"
        >
          <OfferNannyInputPeriodBabbySitting
            v-model:schedule-date-start="offerNanny.schedule.dateStart"
            v-model:period-measure="offerNanny.schedule.period.measure"
            v-model:period-amount="offerNanny.schedule.period.amount"
            v-model:period-term="offerNanny.schedule.period.term"
            @update:is-valid="isValid = $event"
          />
        </div>
        <div
          v-if="
            offerNanny.employment.type === 'part_time' ||
            offerNanny.employment.type === 'full_time'
          "
          id="schedule"
          class="wrapper-section"
        >
          <OfferNannyInputSchedule
            v-model:schedule-value="offerNanny.schedule.value"
            v-model:schedule-comment="offerNanny.schedule.comment"
            v-model:schedule-date-start="offerNanny.schedule.dateStart"
            v-model:period-term="offerNanny.schedule.period.term"
            v-model:period-measure="offerNanny.schedule.period.measure"
            v-model:period-amount="offerNanny.schedule.period.amount"
            @update:is-valid="isValid = $event"
          />
        </div>
        <div id="requirements" class="wrapper-section">
          <OfferNannyInputPerks
            id="requirements-perks"
            v-model:perks="offerNanny.requirements.perks"
            v-model:languages="offerNanny.requirements.languages"
            @update:is-valid="isValid = $event"
          />
        </div>
        <div id="requirements-responsibilities" class="wrapper-section">
          <OfferNannyInputResponsibilities
            v-model:child-care="
              offerNanny.requirements.responsibilities.careChild
            "
            v-model:activities="
              offerNanny.requirements.responsibilities.activities
            "
            v-model:home-care="
              offerNanny.requirements.responsibilities.careHome
            "
            @update:is-valid="isValid = $event"
          />
        </div>
        <div id="details" class="wrapper-section">
          <OfferNannyInputDetails
            v-model:offer-title="offerNanny.details.title"
            v-model:offer-description="offerNanny.details.description"
            v-model:fee-hour="offerNanny.details.rate.hour"
            v-model:fee-month="offerNanny.details.rate.month"
            v-model:offer-tel="offerNanny.details.phone"
            v-model:offer-tel-proven="offerNanny.details.phoneProven"
            @update:is-valid="isValid = $event"
          />
        </div>
        <div id="photo-main" class="wrapper-section">
          <OfferNannyInputPhotoMain
            v-model:imgCroppedSrc="offerNanny.details.photoMain.src"
            @update:imgCroppedFile="offerNanny.details.photoMain.file = $event"
            v-model:imgInputSrc="offerNanny.details.photoMain.originalSrc"
            @update:imgInputFile="
              offerNanny.details.photoMain.originalFile = $event
            "
            v-model:imgInputType="offerNanny.details.photoMain.originalMimetype"
            v-model:imgInputName="offerNanny.details.photoMain.originalFilename"
          />
        </div>
      </div>
      <ButtonPrimarySave
        v-if="isStoreChanged && !isMobile"
        @click="updateOfferNanny()"
        :isDisabled="!isValid"
        class="button-update button-update__container-right"
      />
      <NavMobileBackSave
        v-if="isMobile"
        @save="updateOfferNanny()"
        :isSaveButtonVisible="isStoreChanged"
        :isSaveButtonDisabled="!isValid"
      />
    </template>
  </block-content-13>
</template>

<script>
import { computed, ref, toRefs } from "@vue/reactivity";
import { useRoute } from "vue-router";
import { useOfferNannyStore } from "@/stores/offerNanny";
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, watch } from "vue";

// import useOfferNannyInputAddress from "@/composables/useOfferNannyInputAddress";

import BlockContent13 from "@/layouts/BlockContent13.vue";
import NavSideBarLeft from "@/components/NavSideBarLeft.vue";
import OfferNannyInputAddress from "@/components/Offer/Nanny/OfferNannyInputAddress.vue";
import OfferNannyInputKids from "@/components/Offer/Nanny/OfferNannyInputKids.vue";
import OfferNannyInputEmploymentTypeRadio from "@/components/Offer/Nanny/OfferNannyInputEmploymentTypeRadio.vue";
import OfferNannyInputEmploymentRegularityRadio from "@/components/Offer/Nanny/OfferNannyInputEmploymentRegularityRadio.vue";
import OfferNannyInputPeriodBabbySitting from "@/components/Offer/Nanny/OfferNannyInputPeriodBabbySitting.vue";
import OfferNannyInputSchedule from "@/components/Offer/Nanny/OfferNannyInputSchedule.vue";
import OfferNannyInputPerks from "@/components/Offer/Nanny/OfferNannyInputPerks.vue";
import OfferNannyInputResponsibilities from "@/components/Offer/Nanny/OfferNannyInputResponsibilities.vue";
import OfferNannyInputDetails from "@/components/Offer/Nanny/OfferNannyInputDetails.vue";
import OfferNannyInputPhotoMain from "@/components/Offer/Nanny/OfferNannyInputPhotoMain.vue";
import OfferNannyInputAupairRadio from "@/components/Offer/Nanny/OfferNannyInputAupairRadio.vue";
import ButtonPrimarySave from "@/components/UI/ButtonPrimarySave.vue";
import { useUserStore } from "@/stores/user";
import useGoogleMapsGeocoder from "@/composables/useGoogleMapsGeocoder";
import useCoordsReversed from "@/composables/useCoordsReversed";
import useCommonDictionary from "@/composables/useCommonDictionary";
import useIsMobile from "@/composables/useIsMobile";
import NavMobileBackSave from "@/components/NavMobileBackSave.vue";

export default {
  components: {
    BlockContent13,
    NavSideBarLeft,
    OfferNannyInputAddress,
    OfferNannyInputKids,
    OfferNannyInputEmploymentTypeRadio,
    OfferNannyInputEmploymentRegularityRadio,
    OfferNannyInputPeriodBabbySitting,
    OfferNannyInputSchedule,
    OfferNannyInputPerks,
    OfferNannyInputResponsibilities,
    OfferNannyInputDetails,
    OfferNannyInputPhotoMain,
    OfferNannyInputAupairRadio,
    ButtonPrimarySave,
    NavMobileBackSave,
  },

  setup() {
    const isValid = ref(false);
    const { isMobile } = useIsMobile();

    const offerNanny = useOfferNannyStore();

    // Reset previous offerNanny store
    offerNanny.reset();

    const { details, employment, schedule, requirements } =
      storeToRefs(offerNanny);

    const {
      aupair,
      description,
      isSpecialCare,
      kids,
      location,
      phone,
      rate,
      title,
    } = toRefs(details.value);

    // TODO: fix store self updating on the page load without 500ms timer
    // isPageInititialized with 500ms timer fixes store self updating (due to the date input bugs) on the page load
    const isPageInititialized = ref(false);
    watch(
      () => offerNanny.storeMeta.isInitialized,
      (isInit) => {
        if (isInit === false) return;

        setTimeout(() => {
          isPageInititialized.value = true;
          console.log("Page init");
        }, 500);
      },
      {
        immediate: true,
      }
    );

    const route = useRoute();

    onMounted(() => {
      offerNanny.initOffer(route.params.id);
    });

    onUnmounted(() => {
      // Reset current offerNanny store
      offerNanny.reset();
    });

    const isStoreChanged = ref(false);
    watch(
      [
        employment,
        schedule,
        requirements,
        aupair,
        description,
        isSpecialCare,
        kids,
        location,
        phone,
        rate,
        title,
      ],
      () => {
        if (isPageInititialized.value === true) {
          isStoreChanged.value = true;
        }
      },
      { deep: true }
    );

    // const { coordsLatLng, setCity } = useOfferNannyInputAddress();

    const { coordsLatLngToCityName } = useGoogleMapsGeocoder();
    const { coordsReversed: coordsLatLng } = useCoordsReversed({
      coordsRef: computed(() => offerNanny.details.location.point.coordinates),
      setCoords: (newCoords) => {
        offerNanny.details.location.point.coordinates = newCoords;
      },
    });

    const { DICTIONARY_COMMON } = useCommonDictionary();

    const getUserCity = async () => {
      console.log("GET_USER_CITY_NAME");
      let cityName = null;

      try {
        cityName = await coordsLatLngToCityName(coordsLatLng.value);
      } catch (error) {
        console.log(error, "ERROR: -> RETURN_DEFAULT_CITY_FROM_MARKET");

        // Set default city from cityMarket
        const userCityMarketSlug = offerNanny.details.location.cityMarket;
        cityName = DICTIONARY_COMMON[userCityMarketSlug];
      }

      return cityName;
    };

    const setUserCity = async (cityName) => {
      console.log(`SET_CITY_TO_APPLICATION_LOCATION: ${cityName}`);
      offerNanny.details.location.city = cityName;
    };

    const updateOfferNanny = async () => {
      try {
        const userCity = await getUserCity();
        await setUserCity(userCity);
        await offerNanny.updateOffer();
        const user = useUserStore();
        await user.refetchUserStore();

        isStoreChanged.value = false;
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    const uploadPhotoMain = async () => {
      try {
        await offerNanny.updatePhotoMain();
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    watch(
      () => offerNanny.details.photoMain.file,
      (photoMainCroppedFile) => {
        if (photoMainCroppedFile) uploadPhotoMain();
      }
    );

    const currentHash = computed(() => {
      return route.hash;
    });

    const links = computed(() => [
      {
        slug: "address",
        label: "Adres",
        to: "#address",
        get isActive() {
          return currentHash.value === this.to;
        },
      },
      {
        slug: "aupair",
        label: "Au Pair",
        to: "#aupair",
        get isActive() {
          return currentHash.value === this.to;
        },
      },
      {
        slug: "kids",
        label: "Dziecko",
        to: "#kids",
        get isActive() {
          return currentHash.value === this.to;
        },
      },
      {
        slug: "working_hours",
        label: "Grafik",
        to: "#working-hours",
        get isActiveSublink() {
          return this.sublinks.some((sublink) => sublink.isActive);
        },
        get isActive() {
          return currentHash.value === this.to;
        },
        sublinks: [
          {
            slug: "employment_type",
            label: "Etat",
            to: "#employment-type",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
          {
            slug: "employment_regularity",
            label: "Regularność",
            to: "#employment-regularity",
            get isActive() {
              return currentHash.value === this.to;
            },
            get isHidden() {
              return offerNanny.employment.type !== "part_time";
            },
          },
          {
            slug: "schedule",
            label: "Harmonogram",
            to: "#schedule",
            get isActive() {
              return currentHash.value === this.to;
            },
            get isHidden() {
              return (
                offerNanny.employment.type !== "part_time" &&
                offerNanny.employment.type !== "full_time"
              );
            },
          },
          {
            slug: "period_baby_sitting",
            label: "Okres",
            to: "#period-baby-sitting",
            get isActive() {
              return currentHash.value === this.to;
            },
            get isHidden() {
              return offerNanny.employment.type !== "baby_sitting";
            },
          },
        ],
      },
      {
        slug: "requirements",
        label: "Wymagania",
        to: "#requirements",
        get isActiveSublink() {
          return this.sublinks.some((sublink) => sublink.isActive);
        },
        get isActive() {
          return currentHash.value === this.to;
        },
        sublinks: [
          {
            slug: "requirements_perks",
            label: "Właściwości",
            to: "#requirements-perks",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
          {
            slug: "requirements_responsibilities",
            label: "Obowiązki",
            to: "#requirements-responsibilities",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
        ],
      },
      {
        slug: "details",
        label: "Szczegóły",
        to: "#details",
        get isActive() {
          return currentHash.value === this.to;
        },
      },
      {
        slug: "photo_main",
        label: "Zdjęcie",
        to: "#photo-main",
        get isActive() {
          return currentHash.value === this.to;
        },
      },
    ]);

    return {
      isValid,
      currentHash,
      links,
      offerNanny,
      updateOfferNanny,
      isStoreChanged,
      isPageInititialized,
      coordsLatLng,
      isMobile,
    };
  },
};
</script>

<style scoped>
.content__container-left-fixed {
  height: 100vh;

  display: flex;
  align-items: center;

  position: sticky;
  top: 0;
}
.content__container-right {
  margin: 80px 0;
  width: 100%;

  display: flex;
  flex-flow: column;
  row-gap: 80px;
}
.wrapper-section {
  width: 100%;

  padding: 60px 60px;

  display: flex;
  flex-flow: column;
  align-items: center;

  border-radius: var(--layout-view-wrapper--border-radius);
  background-color: var(--layout-view-wrapper--bg-color);
}

.button-update__container-right {
  position: fixed;
  z-index: 99;
  bottom: 60px;
}

@media (max-width: 1200px) {
  .wrapper-section {
    width: 100%;

    padding: 0;

    display: flex;
    flex-flow: column;
    align-items: center;

    border-radius: var(--layout-view-wrapper--border-radius);
    background-color: var(--layout-view-wrapper--bg-color);
  }
}
</style>
