<template>
  <div class="container">
    <BaseH1 :text="'Dorywczo / regularnie?'" class="h1 container__h1" />

    <InputRadioGroupHorizontal
      v-model="offer.employment.regularity"
      :name="'employment-regularity'"
      :options="optionsEmploymentRegularity"
      :width-small="true"
    />
  </div>
</template>

<script>
import { watch } from "@vue/runtime-core";
import { reactive } from "@vue/reactivity";

import useOfferNannyDictionary from "@/composables/useOfferNannyDictionary";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputRadioGroupHorizontal from "@/components/UI/Base/InputRadioGroupHorizontal.vue";

export default {
  components: {
    BaseH1,
    InputRadioGroupHorizontal,
  },

  props: {
    employmentRegularity: String,
  },

  emits: ["update:employmentRegularity"],

  setup(props, { emit }) {
    const offer = reactive({
      employment: {
        regularity: props.employmentRegularity,
      },
    });

    watch(
      () => offer.employment.regularity,
      (newEmploymentRegularity) =>
        emit("update:employmentRegularity", newEmploymentRegularity)
    );
    watch(
      () => props.employmentRegularity,
      (newEmploymentRegularity) =>
        (offer.employment.regularity = newEmploymentRegularity)
    );

    const { DICTIONARY } = useOfferNannyDictionary();
    const optionsEmploymentRegularity = Object.values(
      DICTIONARY.employment.regularities
    );

    return {
      offer,
      optionsEmploymentRegularity,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}
.container__h1 {
  width: 420px;
  margin-top: -20px;
}
.wrapper-buttons {
  display: flex;
  flex-flow: row;
  column-gap: 40px;
}

@media (max-width: 1200px) {
  .container__h1 {
    width: 100%;
    margin-top: 0;
  }
}
</style>
