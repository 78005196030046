<template>
  <div class="container">
    <BaseH1 :text="'Praca z zamieszkaniem?'" class="h1 container__h1" />

    <div class="wrapper-inputs">
      <InputRadioGroupHorizontal
        v-model="offer.aupair"
        :name="'aupair'"
        :options="optionsAupair"
        :widthSmall="true"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputRadioGroupHorizontal from "@/components/UI/Base/InputRadioGroupHorizontal.vue";

export default {
  components: {
    BaseH1,
    InputRadioGroupHorizontal,
  },

  props: {
    aupair: String,
  },

  emits: ["update:aupair", "update:isValid"],

  setup(props, { emit }) {
    const offer = reactive({
      aupair: props.aupair,
    });

    watch(
      () => offer.aupair,
      (newAupair) => emit("update:aupair", newAupair)
    );
    watch(
      () => props.aupair,
      (newAupair) => (offer.aupair = newAupair)
    );

    const isValid = computed(() => {
      return Boolean(offer.aupair);
    });
    watch(isValid, (newIsValid) => emit("update:isValid", newIsValid), {
      immediate: true,
    });

    const optionsAupair = [
      {
        slug: "in_aupair",
        label: "Tak",
      },
      {
        slug: "not_in_aupair",
        label: "Nie",
      },
    ];

    return {
      offer,
      isValid,
      optionsAupair,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 140px;
}
.container__h1 {
  width: 360px;
  margin-top: -20px;
}
.wrapper-inputs {
  display: flex;
  flex-flow: row;
  column-gap: 40px;
}

@media (max-width: 1200px) {
  .container__h1 {
    width: 100%;
    margin-top: 0;
  }
}
</style>
