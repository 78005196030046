<template>
  <div class="container">
    <BaseH1 :text="'Mnie pasuje...'" />

    <div class="wrapper-buttons">
      <InputRadioGroupVertical
        v-model="offer.employment.type"
        :name="'employment-type'"
        :options="optionsEmploymentType"
      />
      <TextHint
        :text="`Pelen etat — 8 i więcej godzin dziennie
        Część etatu — mniej niż 8 godzin dziennie, co również dotyczy babysittinga`"
      />
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputRadioGroupVertical from "@/components/UI/Base/InputRadioGroupVertical.vue";
import TextHint from "@/components/UI/TextHint.vue";
import useOfferNannyDictionary from "@/composables/useOfferNannyDictionary";

export default {
  components: {
    BaseH1,
    InputRadioGroupVertical,
    TextHint,
  },

  props: {
    employmentType: String,
  },

  emits: ["update:employmentType"],

  setup(props, { emit }) {
    const offer = reactive({
      employment: {
        type: props.employmentType,
      },
    });

    watch(
      () => offer.employment.type,
      (newEmploymentType) => emit("update:employmentType", newEmploymentType)
    );
    watch(
      () => props.employmentType,
      (newEmploymentType) => (offer.employment.type = newEmploymentType)
    );

    const { DICTIONARY } = useOfferNannyDictionary();
    const optionsEmploymentType = Object.values(DICTIONARY.employment.types);

    return {
      offer,
      optionsEmploymentType,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}
.wrapper-buttons {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  column-gap: 40px;
  row-gap: 40px;
}

@media (max-width: 1200px) {
}
</style>
